import { useEffect, useRef } from 'react';

// Similar to normal useEffect, but this one doesn't run on initial render
export const useEffectWithoutInitialRender = (func, dependencies) => {
  const hasRendered = useRef(false);

  useEffect(() => {
    if (hasRendered.current) {
      func();
    } else {
      // eslint-disable-next-line fp/no-mutation
      hasRendered.current = true;
    }
  }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
};
