import { Tooltip, Position } from '@transferwise/components';
import classNames from 'classnames';

import styles from './Endpoint.module.scss';

type Props = {
  link: string;
  method: string;
  uri: string;
  description: string;
};

export const Endpoint = ({ link, method, uri, description }: Props) => (
  <div className={styles.container}>
    <Tooltip label={description} position={Position.TOP}>
      <a className={classNames('text-no-decoration', styles.link)} href={link}>
        <span className={classNames(styles.method, styles[method.toLowerCase()])}>{method}</span>
        <span>{uri}</span>
      </a>
    </Tooltip>
  </div>
);
