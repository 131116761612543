import Link from 'next/link';
import React from 'react';

import { FULL_REFERENCE_URL } from './const';

type Props = {
  resource: string;
  linkText?: string;
};

export const ApiRefLink = ({ resource, linkText = 'Full reference' }: Props) => (
  <Link href={`${FULL_REFERENCE_URL}/${resource}`}>{linkText}</Link>
);
