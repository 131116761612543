import type { Schema } from '@markdoc/markdoc';

export const endpointTag: Schema = {
  render: 'Endpoint',
  selfClosing: true,
  attributes: {
    link: { type: String, required: true },
    method: { type: String, required: true },
    uri: { type: String, required: true },
    description: { type: String },
  },
};
