import type { Schema } from '@markdoc/markdoc';

export const optionTag: Schema = {
  render: 'Option',
  selfClosing: false,
  attributes: {
    value: {
      type: String,
      required: true,
    },
  },
};
