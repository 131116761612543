import React from 'react';

import { ExampleBox } from '../ExampleBox';

import { PostmanRunButton } from './PostmanRunButton';

type Props = {
  title: string;
  collectionId: string;
  workspaceId: string;
};

/*
 * Provide title as parameter for labeling the box
 * collectionId && workspaceId as parameters for directly forking a collection
 */

export const PostmanRunButtonExampleBox = ({ title, collectionId, workspaceId }: Props) => {
  return (
    <ExampleBox title={title}>
      <p>
        We've created a postman collection that follows along the same flows as documented below.
        Please use this to test your integrations. Host environment variable is{' '}
        <code>https://api.sandbox.transferwise.tech</code>
      </p>

      <p>In the collection, we have done a few things to make life a bit easier:</p>
      <ul>
        <li>Included our sandbox environment, where you can fully test your integration.</li>
        <li>Included a set of the common calls required to create new users and profiles.</li>
        <li>
          Included tests that automatically copy details from previous calls to environment
          variables.
        </li>
        <li>Examples of successful call results for quick reference.</li>
      </ul>

      <p>
        All of these additions should allow you to easily test through the entire flow with minimal
        efforts.
      </p>
      <p>
        We recommend as well that you fork the collection. This allows you to receive updates and
        bug fixes as they become available.
      </p>

      <PostmanRunButton workspaceId={workspaceId} collectionId={collectionId} />
    </ExampleBox>
  );
};
