import classNames from 'classnames';
import React, { useState } from 'react';

import { Icon } from '../Icon';

import styles from './ExampleBox.module.scss';

type Props = {
  children: React.ReactNode;
  title?: string;
  copyButton?: boolean;
};

export const ExampleBox = ({ children, title, copyButton = true }: Props) => {
  const [isCopied, setIsCopied] = useState(false);
  const copyToClipboard = () => {
    const textToCopy = React.Children.toArray(children)
      .filter((child): child is React.ReactElement => React.isValidElement(child))
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
      .map((child) => child.props.children)
      .join('');

    void navigator.clipboard.writeText(textToCopy).then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 5000);
    });
  };

  return (
    <div className={styles.container}>
      {title && (
        <div className={classNames('title-5', styles.header)}>
          <div>{title}</div>
          {copyButton && (
            <button type="button" className={styles.copyButton} onClick={copyToClipboard}>
              <Icon icon={isCopied ? 'check' : 'documents'} size="16" />
            </button>
          )}
        </div>
      )}
      <div className={styles.content}>{children}</div>
    </div>
  );
};
