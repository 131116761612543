import classNames from 'classnames';
import React from 'react';

import { getExcerpts } from '../../utils/getExcerpts';

import styles from './SearchResult.module.scss';

type Props = {
  name: string;
  path: string;
  keywords?: string[];
  description?: string;
  markdownText: string;
};

const createBreadcrumbs = (url: string) => {
  const cleanPath = url.replace(/\/$/, '');
  const pathArray = cleanPath.split('/');
  return pathArray.map((item, index) => {
    const link = pathArray.slice(0, index + 1).join('/');
    const title = item
      .replace(/-/g, ' ')
      .replace(/\b\w/g, (char) => char.toUpperCase())
      .replace(/[A][p][i]/g, 'API');
    return { link, title };
  });
};

const SearchResult = ({ name, path, description, keywords, markdownText }: Props) => {
  const breadcrumbs = createBreadcrumbs(path);
  return (
    <div className={classNames(styles.result, 'p-t-3', 'p-b-2')}>
      <b className={classNames(styles.title, 'title-4', 'font-weight-semi-bold')}>
        <a href={path} className="text-no-decoration">
          {name}
        </a>
      </b>
      <div className={styles.breadcrumbs}>
        <div>
          {breadcrumbs.map(({ link, title }, index) => (
            <React.Fragment key={link}>
              <a href={link} className="text-no-decoration small font-weight-semi-bold">
                {title}
              </a>
              {index < breadcrumbs.length - 1 && ' • '}
            </React.Fragment>
          ))}
        </div>
      </div>
      <div>
        <p>{getExcerpts(markdownText, description)}</p>
      </div>
    </div>
  );
};

export default SearchResult;
