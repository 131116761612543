import { Decision as DecisionComponent, DecisionPresentation } from '@transferwise/components';
import { Illustration, IllustrationNames } from '@wise/art';

import { track } from '../../helpers/tracking';

type Props = {
  trackingEventName?: string;
  options: Array<{
    title: string;
    href: string;
    description: string;
    illustration: {
      name: IllustrationNames;
      alt: string;
    };
  }>;
};

export const Decision = ({ trackingEventName, options }: Props) => (
  <div className="m-b-3">
    <DecisionComponent
      presentation={DecisionPresentation.LIST_BLOCK}
      showMediaCircleInList={false}
      options={options.map(({ title, href, description, illustration }) => ({
        title,
        href,
        description,
        onClick: () => trackingEventName && track(trackingEventName, { link: href, title }),
        media: {
          block: <Illustration name={illustration.name} alt={illustration.alt} size="small" />,
          list: <Illustration name={illustration.name} alt={illustration.alt} size="small" />,
        },
      }))}
    />
  </div>
);
