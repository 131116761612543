import type { Node } from '@markdoc/markdoc';

import { Frontmatter } from '../../types';

export const getFrontmatter = (node: Node, filePath: string) => {
  if (!node.attributes.frontmatter) {
    return null;
  }
  try {
    return JSON.parse(node.attributes.frontmatter as string) as Frontmatter;
  } catch (error) {
    throw new Error(
      `Have a look at ${filePath}. Frontmatter seems to be in invalid format. Message: ${
        error instanceof Error ? error.message : ''
      }`,
    );
  }
};
