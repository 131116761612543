import { renderers } from '@markdoc/markdoc';
import classNames from 'classnames';
import React, { useEffect } from 'react';

import { Footer } from '../../../components/Footer';
import { Header } from '../../../components/Header';
import { SideBar } from '../../../components/SideBar';
import { renderConfig } from '../../../markdocConfig';
import { Navigation, Page } from '../../../types';

type Props = {
  page: Page;
  navigation?: Navigation;
  subnavigation?: Navigation;
};

export const App = (props: Props) => {
  const { page, navigation, subnavigation } = props;
  const markdownElement = renderers.react(page.content, React, renderConfig);

  useEffect(() => {
    // Workaround for not scrolling section into view on load
    // https://github.com/vercel/next.js/issues/11109
    if (window.location.hash) {
      setTimeout(() => {
        document.querySelector(window.location.hash)?.scrollIntoView();
      }, 100);
    }
  }, []);

  return (
    <div className="site-body">
      <Header navigation={navigation} subnavigation={subnavigation} />
      <div className={classNames('site-container', { 'site-container--small': !subnavigation })}>
        {subnavigation && <SideBar items={subnavigation.items} />}
        <main className="site-main c-main-content">{markdownElement}</main>
      </div>
      <Footer navigation={navigation} />
    </div>
  );
};
