import type { Schema, ValidationType } from '@markdoc/markdoc';
import { Sentiment } from '@transferwise/components';

const sentimentValues = Object.values<string>(Sentiment);

class AlertType {
  validate(value: string) {
    if (!sentimentValues.includes(value)) {
      return [
        {
          id: 'type',
          level: 'error',
          message: `Invalid alert type "${value}". List of valid types: "${sentimentValues.join(
            '", "',
          )}".`,
        },
      ];
    }
    return [];
  }
}

class AlertAction {
  validate(value: object) {
    const allowedProps = ['aria-label', 'href', 'target', 'text'];
    if (!Object.keys(value).every((key) => allowedProps.includes(key))) {
      return [
        {
          id: 'action',
          level: 'error',
          message: `Alert.action is invalid. List of valid props: "${allowedProps.join('", "')}".`,
        },
      ];
    }
    return [];
  }
}

export const alertTag: Schema = {
  render: 'Alert',
  selfClosing: true,
  attributes: {
    message: { type: String, required: true },
    type: { type: AlertType as ValidationType },
    action: { type: AlertAction as ValidationType },
  },
};
