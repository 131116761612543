import type { Node, Config, Schema, ValidationType } from '@markdoc/markdoc';
import { Tag } from '@markdoc/markdoc';

import { getFrontmatter } from '../../helpers/getFrontmatter';
import { Frontmatter } from '../../types';

const BASE_DIR = 'content';
const RESOURCE_TYPE_REGEX = /(.*)#([\w-]+)$/i;

class ResourceType {
  validate(value: unknown) {
    if (typeof value !== 'string' || !RESOURCE_TYPE_REGEX.test(value)) {
      return [
        {
          id: 'resource',
          level: 'error',
          message: `Invalid resource identifier "${
            typeof value === 'string' ? value : ''
          }". Example of valid format: "/features/webhooks#create"`,
        },
      ];
    }
    return [];
  }
}

export const ReferenceTag: Schema = {
  render: 'Reference',
  selfClosing: true,
  attributes: {
    resource: {
      type: ResourceType as ValidationType,
      render: false,
      required: true,
      errorLevel: 'critical',
    },
    type: { type: String, render: false },
    variables: { type: Object, render: false },
  },
  transform(node: Node, config: Config) {
    const { partials = {} } = config;
    const { resource, variables = {} } = node.attributes;
    const resourceParts = (resource as string).match(RESOURCE_TYPE_REGEX) || [];
    const resourceBase = resourceParts[1]; //  /features/webhooks-notifications
    const resourceEndpoint = resourceParts[2]; // transfers-state-change
    const resourcePath = `${BASE_DIR}${resourceBase}/${resourceEndpoint}.md`;
    const partial = partials[resourcePath] as Node;
    const parentLink = `${resource as string}`;

    if (!partial) {
      return null;
    }

    const scopedConfig = {
      ...config,
      variables: {
        ...config.variables,
        ...(variables as Config['variables']),
        frontmatter: {
          ...(config.variables?.frontmatter as Frontmatter),
          ...getFrontmatter(partial, resourcePath),
          ...((variables as Config['variables'])?.frontmatter as Frontmatter),
        },
      },
    };

    const children = partial.resolve(scopedConfig).transformChildren(scopedConfig);
    return new Tag(this.render, { ...node.attributes, parentLink }, children);
  },
};
