import type { Schema } from '@markdoc/markdoc';

export const selectTag: Schema = {
  render: 'Select',
  selfClosing: true,
  attributes: {
    options: {
      type: Array,
      required: true,
    },
    placeholder: { type: String },
    title: { type: String },
  },
};
