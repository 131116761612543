import classNames from 'classnames';
import Link from 'next/link';

import styles from './IntroCard.module.scss';

type Props = {
  title: string;
  subtitle: string;
  href: string;
  description: string;
  onClick: () => void;
};

export const IntroCard = ({ href, title, subtitle, description, onClick }: Props) => (
  <Link href={href} onClick={onClick} className={styles.link}>
    <div className={classNames(styles.title, 'title-3')}>{title}</div>
    {subtitle ? (
      <div className={classNames('small', 'font-weight-semi-bold', styles.subtitle)}>
        {subtitle}
      </div>
    ) : null}
    <p className={styles.content}>{description}</p>
  </Link>
);
