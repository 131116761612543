import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { getEnvironmentFromURL } from '../helpers/getEnvironmentFromURL';
import { Page } from '../types';

// If page is set to be hidden it will redirect to 404 page.
export const useHiddenPageGuard = ({ config }: { config?: Page['frontmatter'] }) => {
  const router = useRouter();
  const isProduction = getEnvironmentFromURL() === 'production';
  const [isHidden, setIsHidden] = useState(!!config?.hidden);

  useEffect(() => {
    if (isHidden) {
      if (isProduction) {
        void router.push('/404-not-found');
      } else {
        setIsHidden(false);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { isHidden };
};
