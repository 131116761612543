import Script from 'next/script';
import React from 'react';

const GA_TRACKING_ID = 'G-9C4WZXP4V1';

const disableGA = `window["ga-disable-${GA_TRACKING_ID}"] = true;`;

const gtagDataLayerScript = `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
`;

const enableGtagScript = `
  window.addEventListener("accepttwcookieconsent", function() {
    window["ga-disable-${GA_TRACKING_ID}"] = false;
    gtag('config', '${GA_TRACKING_ID}'); // trigger page view
  });
`;

export const LoadScripts = () => {
  return (
    <>
      {/* Disable Google Analytics at first and only enable it after user has given consent */}
      <Script>{disableGA}</Script>
      <Script async src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`} />
      <Script>{gtagDataLayerScript}</Script>
      <Script>{enableGtagScript}</Script>
    </>
  );
};
