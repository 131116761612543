import React from 'react';

import { FuseResults } from '../../types/search';
import SearchResult from '../SearchResult/SearchResult';

type Props = {
  results: Array<FuseResults>;
  keyword: string;
};

const SearchResults = ({ results, keyword }: Props) => {
  return (
    <div className="m-y-3">
      <div className="title-5 m-b-1">
        {results &&
          `${results.length} ${
            results.length > 1 || results.length === 0 ? 'Results' : 'Result'
          } for "${keyword}"`}
      </div>
      {results.length && results.length > 0
        ? results.map(({ item }, index) => {
            const key = index + 1;
            return (
              <SearchResult
                name={item.title}
                path={item.path}
                key={key}
                markdownText={item.content?.text}
                description={item?.excerpt}
                keywords={item?.keywords}
              />
            );
          })
        : null}
    </div>
  );
};
export default SearchResults;
