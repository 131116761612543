import React, { useContext, useMemo } from 'react';

type Option = {
  label: string;
  value: string;
};

type SelectContextType = {
  selected: Option;
  selectOption: (option: Option) => void;
};

export const SelectContext = React.createContext<SelectContextType>({
  selected: { label: '', value: '' },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  selectOption: (option: Option) => {},
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const SelectContextProvider = ({ children }: React.ReactNode) => {
  const [selected, selectOption] = React.useState<Option>({ label: '', value: '' });

  const providerValue = useMemo(
    () => ({
      selected,
      selectOption,
    }),
    [selected],
  );

  return <SelectContext.Provider value={providerValue}>{children}</SelectContext.Provider>;
};

export const useSelectContext = () => useContext(SelectContext);
