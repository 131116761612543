import type { Schema } from '@markdoc/markdoc';

export const fieldTag: Schema = {
  render: 'Field',
  selfClosing: false,
  attributes: {
    name: { type: String, required: true },
    format: { type: String, required: true },
    description: { type: String },
  },
};
