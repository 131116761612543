import type { Schema } from '@markdoc/markdoc';

export const fieldsTag: Schema = {
  render: 'Fields',
  selfClosing: false,
  attributes: {
    title: { type: String },
  },
  children: ['field', 'tag'],
};
