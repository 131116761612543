import { Schema } from '@markdoc/markdoc';

export const imageTag: Schema = {
  render: 'Image',
  selfClosing: true,
  attributes: {
    src: { type: String, required: true },
    alt: { type: String, required: true },
  },
};
