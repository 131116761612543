import { InstructionsList as InstructionsListComponent } from '@transferwise/components';
import { ReactNode } from 'react';

type Props = {
  dos: ReactNode[];
  donts: ReactNode[];
};

export const InstructionsList = ({ dos, donts }: Props) => (
  <InstructionsListComponent dos={dos} donts={donts} />
);
