import { track } from '../../helpers/tracking';

import { IntroCard } from './IntroCard';

type Props = {
  trackingEventName?: string;
  items: Array<{
    title: string;
    subtitle: string;
    href: string;
    description: string;
  }>;
};

export const IntroCards = ({ trackingEventName, items }: Props) => (
  <div className="c-cards">
    {items.map(({ href, title, subtitle, description }) => (
      <IntroCard
        key={title}
        href={href}
        title={title}
        subtitle={subtitle}
        description={description}
        onClick={() => trackingEventName && track(trackingEventName, { link: href, title })}
      />
    ))}
  </div>
);
