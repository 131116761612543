import classNames from 'classnames';
import React from 'react';

import styles from './Heading.module.scss';

type Props = {
  level: number;
  children: string;
  id?: string;
  className?: string;
  inline?: boolean;
};

export const Heading = ({ level, children, id, className, inline = false }: Props) => {
  const HeadingTag = `h${level}` as keyof JSX.IntrinsicElements;
  const HeadingElement = () => (
    <HeadingTag id={id} className={classNames(id ? styles.headingAsLink : null, className)}>
      {children}
    </HeadingTag>
  );

  if (!id) {
    return <HeadingElement />;
  }

  if (inline) {
    return (
      <a href={`#${id}`} className="d-inline-block text-no-decoration">
        <HeadingElement />
      </a>
    );
  }

  return (
    <div className={styles.container}>
      <a href={`#${id}`} className="d-inline-block text-no-decoration">
        <HeadingElement />
      </a>
    </div>
  );
};
