import Link from 'next/link';
import React from 'react';

type Props = {
  children: React.ReactNode;
  parentLink: string;
  type?: 'full' | 'summary';
};

export const Reference = (props: Props) => {
  const { children, parentLink, type = 'full' } = props;
  return (
    <>
      {children}
      {type === 'summary' && <Link href={parentLink}>Full reference</Link>}
    </>
  );
};
