import { RenderableTreeNode, renderers } from '@markdoc/markdoc';
import { SelectInput } from '@transferwise/components';
import Link from 'next/link';
import React from 'react';

import { track } from '../../helpers/tracking';
import { renderConfig } from '../../markdocConfig';
import { Heading } from '../Heading';

import { FULL_REFERENCE_URL } from './const';

export type ContentItem = {
  filename: string;
  content: RenderableTreeNode[];
};

type Props = {
  resource: string;
  id: string;
  items: ContentItem[];
  title?: string;
  type?: 'full' | 'summary' | 'inline';
  linkText?: string;
  isVersionSwitcherVisible?: boolean;
};

export const ApiRef = ({
  resource,
  id,
  items,
  title,
  type,
  linkText = 'Full reference',
  isVersionSwitcherVisible = true,
}: Props) => {
  const [selected, setSelected] = React.useState(items[0].filename);
  const selectedItem = items.find(({ filename }) => filename === selected);

  return (
    <>
      {title && (
        <div className="d-flex justify-content-between align-items-end m-b-1">
          <Heading level={2} id={id} inline>
            {title}
          </Heading>
          {isVersionSwitcherVisible && items.length >= 2 && (
            <div className="p-b-1">
              <SelectInput
                size="md"
                disabled={false}
                defaultValue={selected}
                onChange={(v: string) => {
                  track('Version switched', { resource, version: v });
                  setSelected(v);
                }}
                items={items.map(({ filename }) => ({
                  value: filename,
                  type: 'option',
                }))}
              />
            </div>
          )}
        </div>
      )}
      {selectedItem && renderers.react(selectedItem.content, React, renderConfig)}
      {type === 'summary' && <Link href={`${FULL_REFERENCE_URL}/${resource}`}>{linkText}</Link>}
    </>
  );
};
