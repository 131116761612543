import type { Schema } from '@markdoc/markdoc';

export const decisionTag: Schema = {
  render: 'Decision',
  selfClosing: true,
  attributes: {
    options: { type: Array },
    trackingEventName: { type: String },
  },
};
