import React from 'react';

import styles from './Image.module.scss';

type Props = {
  src: string;
  alt: string;
};

export const Image = ({ src, alt }: Props) => (
  // eslint-disable-next-line jsx-a11y/media-has-caption
  <img src={src} alt={alt} className={styles.image} />
);
