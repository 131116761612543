import { Schema, Tag } from '@markdoc/markdoc';

export const fenceNode: Schema = {
  render: 'Fence',
  attributes: {
    content: { type: String, render: false, required: true },
    language: { type: String },
    process: { type: Boolean, render: false, default: true },
  },
  transform(node, config) {
    const attributes = node.transformAttributes(config);
    const children = node.children.length
      ? node.transformChildren(config)
      : [node.attributes.content];
    return new Tag(this.render, attributes, children);
  },
};
