import type { Schema } from '@markdoc/markdoc';

export const instructionsListTag: Schema = {
  render: 'InstructionsList',
  selfClosing: true,
  attributes: {
    dos: { type: Array },
    donts: { type: Array },
  },
};
