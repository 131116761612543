import type { Schema } from '@markdoc/markdoc';

export const introCardsTag: Schema = {
  render: 'IntroCards',
  selfClosing: true,
  attributes: {
    items: { type: Array },
    trackingEventName: { type: String },
  },
};
