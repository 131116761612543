import type { Schema } from '@markdoc/markdoc';

export const postmanRunButtonExampleBoxTag: Schema = {
  render: 'PostmanRunButtonExampleBox',
  selfClosing: true,
  attributes: {
    title: { type: String, required: true },
    collectionId: { type: String, required: true },
    workspaceId: { type: String, required: true },
  },
};

/*
In order to display the supported Run In Postman button Box that is used as an example flow with a custom title
{% postman-run-button-example-box
title="Partner Account Postman Collection"
collectionId="19030365-42d9c0f7-7eee-41c8-9a5f-1564e80aebcc"
workspaceId="a247415a-a90f-4b3c-88d2-14aa27592db4"
/%}
*/
