
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/public/api-docs/[[...slug]]",
      function () {
        return require("private-next-pages/public/api-docs/[[...slug]].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/public/api-docs/[[...slug]]"])
      });
    }
  