import type { Schema } from '@markdoc/markdoc';

export const postmanRunButtonTag: Schema = {
  render: 'PostmanRunButton',
  selfClosing: true,
  attributes: {
    collectionId: { type: String, required: true },
    workspaceId: { type: String, required: true },
  },
};

/*
In order to display the supported Run In Postman button that bypasses any intermediate modal and directly prompts the user to fork the provided collection, use the following configuration.
{% postman-run-button
collectionId="19030365-42d9c0f7-7eee-41c8-9a5f-1564e80aebcc"
workspaceId="a247415a-a90f-4b3c-88d2-14aa27592db4"
/%}
*/
