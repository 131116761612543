import classNames from 'classnames';
import React from 'react';

import styles from './Section.module.scss';

type Props = {
  children: React.ReactNode;
};

export const Section = ({ children }: Props) => (
  <section className={classNames('d-flex', 'flex-wrap', styles.section)}>{children}</section>
);

// Alignment related components. TODO: extract?!

const baseClassNames = ['align-self-start'];

type SubsectionProps = {
  children: React.ReactNode;
};

export const PanelLeft = ({ children }: SubsectionProps) => (
  <div className={classNames(baseClassNames, styles.left)}>{children}</div>
);

export const PanelRight = ({ children }: SubsectionProps) => (
  <div className={classNames(baseClassNames, styles.right)}>{children}</div>
);
