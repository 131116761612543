import { SelectInput, SelectInputOptionContent } from '@transferwise/components';
import React from 'react';

import styles from './Select.module.scss';
import { useSelectContext } from './SelectContext';

type Props = {
  children: React.ReactNode;
  title: string;
  placeholder: string;
  options: Array<Option>;
};

type Option = {
  label: string;
  value: string;
};

export const Select = ({ children, title, placeholder = '', options = [] }: Props) => {
  const { selected, selectOption } = useSelectContext();

  return (
    <>
      <div className={styles.select}>
        <SelectInput
          placeholder={placeholder}
          onChange={selectOption}
          items={options.map((o) => ({
            type: 'option',
            value: o,
          }))}
          className={styles.select}
          renderValue={(o) => <SelectInputOptionContent title={o.label} />}
        />
      </div>
      {children}
    </>
  );
};
