import React from 'react';

type Props = {
  children: React.ReactNode;
  title?: string;
};

export const Fields = ({ children, title }: Props) => (
  <div className="m-y-3">
    {title && <div className="title-4 m-b-1">{title}</div>}
    {children}
  </div>
);
