import React from 'react';

import { useSelectContext } from './SelectContext';

type Props = {
  children: React.ReactNode;
  value: string;
};

export const Option = ({ children, value }: Props) => {
  const { selected } = useSelectContext();
  if (selected.value !== value) {
    return null;
  }
  return children;
};
