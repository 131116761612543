import React from 'react';

import styles from './Field.module.scss';

type Props = {
  name: string;
  format: string;
  description?: string;
  children?: React.ReactNode;
};

export const Field = ({ name, format, description, children }: Props) => (
  <div className={styles.field}>
    <b>{name}</b>
    <span className="m-l-1 text-muted">{format}</span>
    <div className={styles.description}>{children || description}</div>
  </div>
);
