import classNames from 'classnames';

import styles from './HeroBanner.module.scss';

export const HeroBanner = () => (
  <div className={styles.hero}>
    <span className={classNames(styles.title, 'display-2', 'm-y-section-3', 'p-y-section-2')}>
      Start creating with Wise Platform
    </span>
  </div>
);
