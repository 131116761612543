import path from 'path';

import type { Node, Config, Schema, ValidationType } from '@markdoc/markdoc';
import { Tag } from '@markdoc/markdoc';
import semver, { SemVer } from 'semver';

import { getFrontmatter } from '../../helpers/getFrontmatter';
import { Frontmatter } from '../../types';

const API_REF_BASE_DIR = 'content/api-docs/api-reference/';
const RESOURCE_TYPE_REGEX = /^([\w-]+)#([\w-]+)$/i;

class ResourceType {
  validate(value: unknown) {
    if (typeof value !== 'string' || !RESOURCE_TYPE_REGEX.test(value)) {
      return [
        {
          id: 'resource',
          level: 'error',
          message: `Invalid resource identifier "${
            typeof value === 'string' ? value : ''
          }". Example of valid format: "transfers#create"`,
        },
      ];
    }
    return [];
  }
}

export const apiRefTag: Schema = {
  render: 'ApiRef',
  selfClosing: true,
  attributes: {
    resource: {
      type: ResourceType as ValidationType,
      render: false,
      required: true,
      errorLevel: 'critical',
    },
    title: { type: String, render: false },
    type: { type: String, render: false },
    linkText: { type: String, render: false },
    isVersionSwitcherVisible: { type: Boolean, render: false, default: true },
    variables: { type: Object, render: false },
  },
  transform(node: Node, config: Config) {
    const { partials = {} } = config;
    const { resource, variables = {}, title, type } = node.attributes;
    const resourceParts = (resource as string).match(RESOURCE_TYPE_REGEX) || [];
    const base = resourceParts[1]; // e.g. "transfers"
    const endpoint = resourceParts[2]; // "create"
    const resourcePath = `${API_REF_BASE_DIR}${base}/${endpoint}`; // "content/api-docs/api-reference/transfers/create"

    // Goes through all markdown files (partials) and returns all matching the given "resource" identifier
    const matchingPartials = Object.keys(partials).filter(
      (partialPath) =>
        // find specific file (transfers/create.md) or every file in folder (transfers/create/v1.md, ...)
        partialPath === `${resourcePath}.md` || partialPath.startsWith(`${resourcePath}/`),
    );

    if (!matchingPartials.length) {
      throw Error(`Could not find a file or folder ${resourcePath}`);
    }

    if (type === 'inline') {
      return new Tag('ApiRefLink', { ...node.attributes });
    }

    if (matchingPartials.length > 1 && !title) {
      throw Error(
        'Please provide a "title" for versioned endpoints. Example: {% api-ref title="Hello" ... /%}.',
      );
    }

    const items = matchingPartials.map((partialPath) => {
      const partial = partials[partialPath] as Node;
      const scopedConfig = {
        ...config,
        variables: {
          ...config.variables,
          ...(variables as Config['variables']),
          frontmatter: {
            ...(config.variables?.frontmatter as Frontmatter),
            ...getFrontmatter(partial, resourcePath),
            ...((variables as Config['variables'])?.frontmatter as Frontmatter),
          },
        },
      };

      return {
        filename: path.parse(partialPath).name,
        content: partial.resolve(scopedConfig).transformChildren(scopedConfig),
      };
    });

    // Sort the array by version descending or alphabetically
    items.sort((a, b) => {
      if (semver.valid(semver.coerce(a.filename)) && semver.valid(semver.coerce(b.filename))) {
        return semver.rcompare(
          semver.coerce(a.filename) as SemVer,
          semver.coerce(b.filename) as SemVer,
        );
      }
      return a.filename.localeCompare(b.filename);
    });

    return new Tag(this.render, {
      ...node.attributes,
      id: endpoint,
      key: resource as string,
      items,
    });
  },
};
